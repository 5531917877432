import React, { useEffect, useRef, useState } from 'react';
import { NavbarToggler } from 'reactstrap';
import { SgwtAccountCenter } from './SgwtAccountCenter';
import { useAppContext } from 'components/common/AppProvider';
import classNames from 'classnames';
import { HeaderMenu } from 'components/Layout/Header/headerMenu';
import { HeaderLogo } from './HeaderLogo';
import { RoutePaths } from 'routes/RoutePaths';
import { NavLink } from 'react-router-dom';

export const Header: React.FC = () => {
    const { state: { search: { quickSearch: { pictureType, sectorId, searchTerms } } } } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const close = () => setIsOpen(false);
        const clickoutClose = (ev: MouseEvent) => {
            if (ev.target && !headerRef?.current?.contains(ev.target as Node)) {
                close();
            }
        };

        window.addEventListener('click', clickoutClose);
        return () => {
            window.removeEventListener('click', clickoutClose);
        };
    }, []);

    return <header className="header" ref={headerRef}>
        <div className="navbar">
            <div className="navbar-title">
                <NavLink
                    to={RoutePaths.Home.url(searchTerms, pictureType, sectorId)}
                    className="navbar-title-link"
                >
                    <HeaderLogo />
                </NavLink>
                <NavbarToggler onClick={toggle} className="d-block d-lg-none me-2" />
                <HeaderMenu className={classNames('bg-lvl2 navbar-menu-dropdown dropdown-menu w-100', { show: isOpen })} />
            </div>

            <div className="navbar-content">
                <HeaderMenu className={'navbar-navigation p-0 ms-0 me-4 d-none d-lg-flex'} />

                <div className="flex-fill" />

                <SgwtAccountCenter />
            </div>
        </div>
    </header>;
};
