import { ApiRequest, Reducer } from 'store';

export const initialState = {
    categories: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categoryNatures: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
} as const;

export type ReferentialState = typeof initialState;

export const ReferentialReducer: Reducer<ReferentialState> = (state = initialState, action) => {
    switch (action.type) {
        case '@REFERENTIAL/FETCH_CATEGORIES':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORIES_FAILURE':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_NATURES':
            return {
                ...state,
                categoryNatures: {
                    ...state.categoryNatures,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_NATURES_SUCCESS':
            return {
                ...state,
                categoryNatures: {
                    ...state.categoryNatures,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_NATURES_FAILURE':
            return {
                ...state,
                categoryNatures: {
                    ...state.categoryNatures,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
