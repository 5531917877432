import { toastError } from 'components/common/Toaster';
import { LogsApi } from 'services/ApiService';
import { ApiException } from 'services/ApiService/Http';
import { ClientLogError } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { initSGConnect } from 'services/SgConnect';

export interface IError {
    name?: string;
    message: string;
    stack?: string;
}

export let lastError: IError | undefined = undefined;

export const logError = (error: Error | unknown): Promise<void> => {
    if (error instanceof ApiException) {
        switch (error.status) {
            case 401:
                initSGConnect();
                return Promise.resolve();
        }
        return Promise.resolve();
    }

    if (error instanceof Error && error.name !== 'AbortError') {
        toastError(error.message);

        if (lastError?.message !== error.message) {
            lastError = {
                message: error.message,
                name: error.name,
                stack: error.stack,
            };
            const isDocumentDefined = typeof document !== 'undefined';
            const log: ClientLogError = {
                message: error.message,
                name: error.name,
                stackTrace: error.stack,
                pageUrl: isDocumentDefined ? document.location?.pathname : undefined,
            };

            LogsApi.error(log)
                .catch((err) => {
                    throw err;
                });
        }
    }

    return Promise.resolve();
};
