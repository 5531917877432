import { ApiRequest, Reducer } from 'store';
import { BannerDistributionConfiguration, SearchBannersConfigurationsRequest } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';

export interface IPictureGroupRequestMap {
    readonly [pictureGroupId: string]: ApiRequest;
}

export interface IPictureGroupConfigRequestMap {
    readonly [pictureGroupId: string]: ApiRequest<BannerDistributionConfiguration>;
}

export interface IUploadBannerConfigurationsMap {
    readonly [pictureGroupId: string]: BannerDistributionConfiguration;
}

export const DEFAULT_SEARCH_BANNERS_FILTERS_TAKE = 10;
export const DEFAULT_SEARCH_BANNERS_FILTERS: SearchBannersConfigurationsRequest = {
    onlyActive: true,
    skip: 0,
    take: DEFAULT_SEARCH_BANNERS_FILTERS_TAKE,
};

export const initialState = {
    updateRequest: undefined as ApiRequest | undefined,
    uploadingBanner: false as boolean,
    updatingBanner: false as boolean,
    uploadingBannerConfiguration: false as boolean,
    deactivatingBannerDistribution: {} as { [bannerDistributionId: string]: boolean },
    reactivatingBannerDistribution: {} as { [bannerDistributionId: string]: boolean },
    pictureGroups: {} as IPictureGroupRequestMap,
    pictureGroupsConfigurations: {} as IPictureGroupConfigRequestMap,
    searchBannersConfigurations: {
        filters: {
            ...DEFAULT_SEARCH_BANNERS_FILTERS,
        },
        data: undefined,
        isFetching: false,
        didInvalidate: true,
        totalResults: 0,
    } as ApiRequest<string[]> & {
        filters: SearchBannersConfigurationsRequest,
    } & { totalResults: number },
} as const;

export type PicturesState = typeof initialState;

export const PicturesReducer: Reducer<PicturesState> = (state = initialState, action) => {
    switch (action.type) {
        case '@PICTURES/UPDATE_PICTURE_GROUP_REQUEST':
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isFetching: true,
                    didInvalidate: false,
                },
            };

        case '@PICTURES/UPDATE_PICTURE_GROUP_SUCCESS':
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isFetching: false,
                    didInvalidate: false,
                },
            };

        case '@PICTURES/UPDATE_PICTURE_GROUP_FAILURE':
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isFetching: false,
                    didInvalidate: false,
                },
            };

        case '@PICTURES/FETCH_PICTURE_GROUP_REQUEST':
            return {
                ...state,
                pictureGroups: createFetchPictureGroupsState(state, action.payload.pictureGroupIds, {
                    isFetching: true,
                    didInvalidate: true,
                }),
            };

        case '@PICTURES/FETCH_PICTURE_GROUP_SUCCESS':
            return {
                ...state,
                pictureGroups: createFetchPictureGroupsState(state, action.payload.pictureGroupIds, {
                    isFetching: false,
                    didInvalidate: false,
                }),
            };

        case '@PICTURES/FETCH_PICTURE_GROUP_FAILURE':
            return {
                ...state,
                pictureGroups: createFetchPictureGroupsState(state, action.payload.pictureGroupIds, {
                    isFetching: false,
                    didInvalidate: true,
                }),
            };

        case '@PICTURES/UPLOAD_BANNER_REQUEST':
            return {
                ...state,
                uploadingBanner: true,
            };

        case '@PICTURES/UPLOAD_BANNER_SUCCESS':
        case '@PICTURES/UPLOAD_BANNER_FAILURE':
            return {
                ...state,
                uploadingBanner: false,
            };

        case '@PICTURES/UPDATE_BANNER_REQUEST':
            return {
                ...state,
                updatingBanner: true,
            };

        case '@PICTURES/UPDATE_BANNER_SUCCESS':
            return {
                ...state,
                updatingBanner: false,
                pictureGroups: {
                    ...state.pictureGroups,
                    [action.payload.pictureGroupId]: {
                        ...state.pictureGroups[action.payload.pictureGroupId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
                pictureGroupsConfigurations: {
                    ...state.pictureGroupsConfigurations,
                    [action.payload.pictureGroupId]: {
                        ...state.pictureGroupsConfigurations[action.payload.pictureGroupId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PICTURES/UPDATE_BANNER_FAILURE':
            return {
                ...state,
                updatingBanner: false,
            };

        case '@PICTURES/GET_BANNERS_CONFIGURATIONS_REQUEST':
            return {
                ...state,
                pictureGroupsConfigurations: createFetchPictureGroupsConfigurationssState(state, action.payload.pictureGroupIds, {
                    isFetching: true,
                    didInvalidate: true,
                }),
            };

        case '@PICTURES/GET_BANNERS_CONFIGURATIONS_SUCCESS':
            let stateConfigs = { ...state.pictureGroupsConfigurations };

            for (const configuration of action.payload.configurations) {
                stateConfigs = {
                    ...stateConfigs,
                    [configuration.pictureGroupId as string]: {
                        ...stateConfigs[configuration.pictureGroupId as string],
                        isFetching: false,
                        didInvalidate: false,
                        data: configuration,
                    },
                };
            }

            return {
                ...state,
                pictureGroupsConfigurations: {
                    ...stateConfigs,
                },
            };

        case '@PICTURES/GET_BANNERS_CONFIGURATIONS_FAILURE':
            return {
                ...state,
                pictureGroupsConfigurations: createFetchPictureGroupsConfigurationssState(state, action.payload.pictureGroupIds, {
                    isFetching: false,
                    didInvalidate: true,
                }),
            };

        case '@PICTURES/DEACTIVATE_BANNER_REQUEST':
            return {
                ...state,
                deactivatingBannerDistribution: {
                    ...state.deactivatingBannerDistribution,
                    [action.payload.bannerDistributionId]: true,
                },
            };

        case '@PICTURES/DEACTIVATE_BANNER_SUCCESS':
            return {
                ...state,
                deactivatingBannerDistribution: {
                    ...state.deactivatingBannerDistribution,
                    [action.payload.bannerDistributionId]: false,
                },
                pictureGroupsConfigurations: {
                    ...state.pictureGroupsConfigurations,
                    [action.payload.pictureGroupId]: {
                        ...state.pictureGroupsConfigurations[action.payload.pictureGroupId],
                        didInvalidate: true,
                    },
                },
                searchBannersConfigurations: {
                    ...state.searchBannersConfigurations,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PICTURES/DEACTIVATE_BANNER_FAILURE':
            return {
                ...state,
                deactivatingBannerDistribution: {
                    ...state.deactivatingBannerDistribution,
                    [action.payload.bannerDistributionId]: false,
                },
            };

        case '@PICTURES/REACTIVATE_BANNER_REQUEST':
            return {
                ...state,
                reactivatingBannerDistribution: {
                    ...state.reactivatingBannerDistribution,
                    [action.payload.bannerDistributionId]: true,
                },
            };

        case '@PICTURES/REACTIVATE_BANNER_SUCCESS':
            return {
                ...state,
                reactivatingBannerDistribution: {
                    ...state.reactivatingBannerDistribution,
                    [action.payload.bannerDistributionId]: false,
                },
                pictureGroupsConfigurations: {
                    ...state.pictureGroupsConfigurations,
                    [action.payload.pictureGroupId]: {
                        ...state.pictureGroupsConfigurations[action.payload.pictureGroupId],
                        didInvalidate: true,
                    },
                },
                searchBannersConfigurations: {
                    ...state.searchBannersConfigurations,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PICTURES/REACTIVATE_BANNER_FAILURE':
            return {
                ...state,
                reactivatingBannerDistribution: {
                    ...state.reactivatingBannerDistribution,
                    [action.payload.bannerDistributionId]: false,
                },
            };

        case '@PICTURES/SEARCH_BANNERS_CONFIGURATIONS_REQUEST':
            return {
                ...state,
                searchBannersConfigurations: {
                    ...state.searchBannersConfigurations,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@PICTURES/SEARCH_BANNERS_CONFIGURATIONS_SUCCESS':
            return {
                ...state,
                searchBannersConfigurations: {
                    ...state.searchBannersConfigurations,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.bannerDistributionIds,
                    totalResults: action.payload.totalResults,
                },
            };

        case '@PICTURES/SEARCH_BANNERS_CONFIGURATIONS_FAILURE':
            return {
                ...state,
                searchBannersConfigurations: {
                    ...state.searchBannersConfigurations,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PICTURES/SEARCH_BANNERS_CONFIGURATIONS_CHANGE_PAGE':
            if (action.payload.skip != state.searchBannersConfigurations?.filters?.skip) {
                return {
                    ...state,
                    searchBannersConfigurations: {
                        ...state.searchBannersConfigurations,
                        isFetching: false,
                        didInvalidate: true,
                        filters: {
                            ...state.searchBannersConfigurations.filters,
                            skip: action.payload.skip,
                        },
                    },
                };
            }

            return state;

        case '@PICTURES/SEARCH_BANNERS_CONFIGURATIONS_SET_ACTIVE':
            if (state.searchBannersConfigurations.filters?.onlyActive === action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                searchBannersConfigurations: {
                    ...state.searchBannersConfigurations,
                    filters: {
                        ...state.searchBannersConfigurations.filters,
                        onlyActive: action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        default:
            return state;
    }
};

const createFetchPictureGroupsState = (state: PicturesState, pictureGroupIds: string[], apiRequest: ApiRequest) => {
    let statePictureGroups = {
        ...state.pictureGroups,
    };

    for (const pictureGroupId of pictureGroupIds) {
        statePictureGroups = {
            ...statePictureGroups,
            [pictureGroupId]: {
                ...state.pictureGroups[pictureGroupId],
                ...apiRequest,
            },
        };
    }
    return statePictureGroups;
};

const createFetchPictureGroupsConfigurationssState = (state: PicturesState, pictureGroupIds: string[], apiRequest: ApiRequest) => {
    let statePictureGroupsConfigurations = {
        ...state.pictureGroupsConfigurations,
    };

    for (const pictureGroupId of pictureGroupIds) {
        statePictureGroupsConfigurations = {
            ...statePictureGroupsConfigurations,
            [pictureGroupId]: {
                ...state.pictureGroupsConfigurations[pictureGroupId],
                ...apiRequest,
            },
        };
    }
    return statePictureGroupsConfigurations;
};
