import React from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import classNames from 'classnames';

export const HeaderMenu: React.FC<{ className?: string }> = ({ className }) => {
    const { pathname } = useLocation();

    return <ul className={className} data-bs-popper="static" style={{ listStyleType: 'none' }}>
        <li className="navbar-item">
            <NavLink to={RoutePaths.Home.url()}
                     className={classNames('navbar-link', { active: !matchPath({ path: RoutePaths.EmailBanners.route, end: true }, pathname) })}>
                Pictures
            </NavLink>
        </li>
        <li className="navbar-item">
            <NavLink to={RoutePaths.EmailBanners.route} className="navbar-link">
                Email Banners
            </NavLink>
        </li>
    </ul>;
};
