import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Header } from './Header';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SplashScreen } from './SplashScreen';
import { SgwtConnect } from './SgwtConnect';

interface IProps {
    children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => (
    <div>
        <Header />
        <div className="d-flex justify-content-center px-4 mt-3 mb-4" style={{ minHeight: 'calc(100vh - 11.4em)' }}>
            <ErrorBoundary>{children}</ErrorBoundary>
        </div>
        <Footer />
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            closeButton={false}
            icon={false}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <HelpCenter />
        <SgwtConnect />
        <SplashScreen />
    </div>
);
