import React from 'react';

export const HeaderLogo: React.FC = () => {
    return <>
        <div className="navbar-logo">
            <img src="https://shared.sgmarkets.com/assets/images/logo/socgen_logo.svg" alt="SG logo Glyph" />
        </div>

        <div className="navbar-title-divider" />
        <div className="navbar-service-name">
            Picture
            <br />
            Collection
        </div>
    </>;
};
