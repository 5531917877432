import { AppContextAction } from './AppContext/AppContextActions';
import { AppContextReducer } from './AppContext/AppContextReducer';
import { NormalizrAction } from './Normalizr/NormalizrAction';
import { NormalizrReducer } from './Normalizr/NormalizrReducer';
import { PersonsAction } from './Persons/PersonsActions';
import { PersonsReducer } from './Persons/PersonsReducer';
import { PicturesAction } from './Pictures/PicturesAction';
import { PicturesReducer } from './Pictures/PicturesReducer';
import { SearchAction } from './Search/SearchActions';
import { SearchReducer } from './Search/SearchReducer';
import { SectorsAction } from './Sectors/SectorsActions';
import { SectorsReducer } from './Sectors/SectorsReducer';
import { Reducer as BaseReducer, combineReducers } from './storeHelper';
import { ReferentialAction } from 'store/Referential/ReferentialActions';
import { ReferentialReducer } from 'store/Referential/ReferentialReducer';

export type Action =
    | { type: 'TEST' }
    | AppContextAction
    | NormalizrAction
    | PersonsAction
    | SearchAction
    | SectorsAction
    | PicturesAction
    | ReferentialAction
    ;

const reducers = {
    appContext: AppContextReducer,
    entities: NormalizrReducer,
    persons: PersonsReducer,
    sectors: SectorsReducer,
    search: SearchReducer,
    pictures: PicturesReducer,
    referential: ReferentialReducer,
};

type StateReducersMapObject = typeof reducers;
type StateKeys = keyof StateReducersMapObject;
export type State = { [key in StateKeys]: ReturnType<StateReducersMapObject[key]> };

export const reducer = combineReducers<State, Action>(reducers);

export const initialState = reducer({} as State, {} as any);

export type Reducer<S = any> = BaseReducer<S, Action>;
